import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import toolObj from '@/assets/js/tool'
const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    redirect: '/recyclingBinManagement',
    children: [
      {
        path: '/recyclingBinManagement',
        name: '回收点管理',
        component: () => import( /* webpackChunkName: "about" */ '../views/recyclingBinManagement/index.vue'),
      },
      {
        path: '/userManagement',
        name: '用户管理',
        component: () => import( /* webpackChunkName: "about" */ '../views/userManagement/index.vue'),
      },
      {
        path: '/reportManagement',
        name: '回收报表',
        component: () => import( /* webpackChunkName: "about" */ '../views/reportManagement/index.vue'),
      },
      {
        path: '/goodsManagement',
        name: '商品管理',
        component: () => import( /* webpackChunkName: "about" */ '../views/goodsManagement/index.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const isLogin = toolObj.getCookie('token')
  if (!isLogin) {
    if (to.path == '/') {
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})
export default router
