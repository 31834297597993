import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/base.less';
import './assets/css/component.less';
import toolObj from "./assets/js/tool";
import "./assets/js/common"; // 引入通用方法
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$http = axios
Vue.prototype.toolObj = toolObj;
import {
  formPost, postRequest,uploadFileRequest
} from './assets/js/axios';
Vue.prototype.formPost = formPost;
Vue.prototype.postRequest = postRequest;
Vue.prototype.uploadFileRequest = uploadFileRequest;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
