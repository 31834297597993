import axios from 'axios'
export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${url}`,
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const formPost = (url, params) => {
    return axios({
        method: 'post',
        url: `${url}`,
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

export const uploadFileRequest = (url, params, load) => {

    return axios({
        method: 'post',
        url: `${url}`,
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
