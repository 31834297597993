import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import axios from 'axios'
import toolObj from '@/assets/js/tool'
axios.interceptors.request.use(config => {
  // config.headers.Authorization = 'Bearer ' + 1234
  config.headers.Authorization = toolObj.getCookie('token')
  config.headers.userId = toolObj.getCookie('userId')
  //
  // 判断是否有params参数
  // console.log(toolObj.getCookie('token'));
  // if (!res.params) {
  //   res.params = {}
  // }
  // if (res.url != '/identify/auth/login') {
  // res.params.token = toolObj.getCookie('token')
  //  res.params.token='004A1F006BE34D6D95E1E2D7AF7588D6';
  // }
  return config
}, err => {
  return Promise.resolve(err)
})
export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

